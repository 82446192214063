import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface LogEntry {
  message: string;
  details?: string;
  type?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiLoggerService {
  url = '/log-client';

  alreadyLoggedErrors = [];

  constructor(
    private http: HttpClient
  ) { }

  error(error) {
    if (error instanceof HttpErrorResponse) {
      return;
    }

    const entry = this.buildLogEntryFromError(error);

    if ((typeof entry.message === 'string') && entry.message.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      return;
    }

    const errorID = entry.message + '_' + entry.url;

    if (this.alreadyLoggedErrors.includes(errorID)) {
      return;
    }

    this.alreadyLoggedErrors.push(errorID);
    this.http.post(this.url, { error: entry }).subscribe();
  }

  buildLogEntryFromError(error) {
    const entry = {} as LogEntry;

    if (error instanceof Error) {
      entry.type = error.name;
      entry.message = error.message;
      entry.details = error.stack;
    } else {
      entry.message = error;
    }

    entry.url = window.location.pathname;

    return entry;
  }
}
