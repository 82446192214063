import { Injectable } from '@angular/core';
import {
  PresenceState, PresenceStatus, Reservation,
  ReservationError, ReservationPanierItem, ReservationPresence, ReservationStatus
} from '@app/models/reservation';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, switchMap, tap } from 'rxjs/operators';
import { FamilyService } from './family.service';
import { Inscription } from '@app/models/inscription';
import { Family } from '@app/models/family';
import { Facture } from '@app/models/facturation';
import { HTTP_OPTIONS_JSON } from './api-crud.service';
import { ApiPlanningData, PlanningData } from '@app/components/_user/reservation/planning/planning-data';
import { Consumer } from '@app/models/consumer';
import moment from 'moment';
import { DATE_FORMAT } from './planning.service';

interface PanierFactureData {
  facture?: Facture;
  montantAvoir?: number;
  errors?: ReservationError[];
}

export function computePresenceStatus(pr: ReservationPresence, reservation?: Reservation): PresenceStatus {
  if (reservation?.state === 'Expiree') {
    return pr.askCancel ? 'expired_canceling' : 'expired';
  }

  if (pr.askCancel) {
    return 'canceling';
  }

  if (!pr.id) {
    return 'new';
  }

  switch (pr.state) {
    case PresenceState.waiting: return 'waiting';
    case PresenceState.canceled: return 'canceled';
    case PresenceState.denied: return 'denied';
    case PresenceState.accepted: return 'accepted';
    case PresenceState.attente: return 'liste_attente';
  }
}

export function translateReservationStatus(resa: Reservation): ReservationStatus {
  if (resa.otherAccount) {
    return 'other_account';
  }

  switch (resa.state) {
    case 'EnAttente': return 'waiting';
    case 'EnAttenteDePaiement': return 'waiting_payment';
    case 'Acceptee': return 'accepted';
    case 'Refusee': return 'denied';
    case 'Annulee': return 'canceled';
    case 'Expiree': return 'expired';
    case 'PartiellementAccepte': return 'partially_accepted';
  }
}

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  constructor(
    private http: HttpClient,
    private familyService: FamilyService,
  ) { }

  getFamilyInscriptions(family: Family | number, type?: string) {
    const id = typeof family === 'object' ? family.id : family;

    return this.http.get<Inscription[]>(`familles/${id}/inscriptions${type ? '/' + type : ''}`);
  }

  submitReservation(resa: Reservation, forSummary = false) {
    const data = {
      idPeriode: resa.idPeriode,
      idPortailPeriode: resa.idPortailPeriode,
      idInscription: resa.idInscription,
      presences: resa.presences.filter(pr => pr.attente !== -1)
    };

    const url = this.familyService.getCurrentFamilyDependantUrl('reservations' + (forSummary ? '/prevalidate' : ''));

    return this.http.post(url, data, HTTP_OPTIONS_JSON);
  }

  getReservationCart(): Observable<ReservationPanierItem[]> {

    if (this.familyService.currentFamily) {
      return this.http.get<ReservationPanierItem[]>(`familles/${this.familyService.currentFamily.id}/panier`).pipe(
        map(data => {
          data = data || [];

          data.forEach(item => {
            item.reservations = item.reservations.map(r => this.adaptReservation(r));
          });

          return data;
        })
      );
    }

    return of(null);
  }

  getFactureForReservations(idRegie: number, idReservations: number[]) {
    const idFamily = this.familyService.currentFamily.id;

    return this.http.post<PanierFactureData>(`familles/${idFamily}/panier/${idRegie}/facture`, { reservations: idReservations });
  }

  validatePanier(idRegie: number, reservations: number[], montantPaiement: number, backUrl: string) {
    const idFamily = this.familyService.currentFamily.id;

    return this.http.post<any>(`familles/${idFamily}/panier/${idRegie}/valider`, { reservations, montantPaiement, backUrl });
  }

  loadPlanningData(family: Family | number, planningData: PlanningData, dates?: { start: string, end: string }, silent?: boolean) {
    const idFamily = typeof family === 'object' ? family.id : family;

    let url = `reservations/planning-data?family=` + idFamily;

    if (dates) {
      // load explicit dates
      url += '&startDate=' + dates.start + '&endDate=' + dates.end;
    }

    // exclude already loaded
    if (planningData.loadedDates?.length) {
      url += '&loaded_dates=' + JSON.stringify(planningData.loadedDates);
    }

    // tell which periodes & reservations we already know
    if (planningData.reservations?.length) {
      url += '&loaded_reservations=' + planningData.reservations?.map(r => r.id).join(',');
    }

    // url += '&loaded_periodes=' + planningData.periodes.map(p => p.idPortail).join(',');

    if (planningData.activityDetails?.length) {
      url += '&loaded_activities=' + planningData.activityDetails?.map(a => a.id + '-' + a.periode).join(',');
    }

    if (!planningData.feries?.length) {
      url += '&load_feries=1';
    }

    return this.http.get<ApiPlanningData>(url).pipe(
      tap(resp => {
        if (resp.reservations) {
          resp.reservations = resp.reservations.map(item => this.adaptReservation(item));
        }

        planningData.addPlanningData(resp);
        planningData.setLoadedDates(dates ? dates : 'all');

        if (!silent) {
          planningData.triggerAllChanges();
        }
      })
    );
  }

  loadPlanningDataProgressive(family: Family | number, planningData: PlanningData) {
    const firstLoad = planningData.getCurrentMonthBounds();
    const secondLoad = {
      start: moment(firstLoad.start).subtract(3, 'month').format(DATE_FORMAT),
      end: moment(firstLoad.end).add(3, 'month').format(DATE_FORMAT)
    };

    return this.loadPlanningData(family, planningData, firstLoad).pipe(
      switchMap(_ => this.loadPlanningData(family, planningData, secondLoad)),
      switchMap(_ => this.loadPlanningData(family, planningData)),
    );
  }

  adaptReservation(reservation: Reservation) {
    reservation.status = translateReservationStatus(reservation);

    if (reservation.presences) {
      for (const prez of reservation.presences) {
        if (!reservation.startDate || prez.date < reservation.startDate) { reservation.startDate = prez.date; }
        if (!reservation.endDate || prez.date > reservation.endDate) { reservation.endDate = prez.date; }
        prez.status = computePresenceStatus(prez, reservation);
      }
    }

    reservation.idConsumer = Consumer.buildIdConsumer(reservation.idChild, reservation.idAdult);
    reservation.number = typeof reservation.id === 'number' ? ('' + reservation.id).padStart(6, '0') : reservation.id;

    return reservation;
  }
}
