import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-hidden-field',
  styles: [``],
  template: /*html*/``,
})


export class FormHiddenFieldComponent extends FieldType {}
