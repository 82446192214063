import { Injectable, ErrorHandler } from '@angular/core';
import { ApiLoggerService } from './api-logger.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

  constructor(
    private logger: ApiLoggerService
  ) {
    super();
  }

  handleError(error) {
    super.handleError(error);

    // ne pas logger les erreurs de Webdev .. ?

    // Je désactive ceci pour le moment car cela ne nous sert pas vraiment, et génère potentiellement trop de requêtes  
    // TODO : revoir (pour du plus light) ou retirer ça complétement

    // if (!(typeof error === 'string' && error.includes('Code erreur'))) {
    //   this.logger.error(error);
    // }
  }
}
