<mat-toolbar mat-dialog-title color="primary" class="title-toolbar">
  <button mat-icon-button [mat-dialog-close] class="close-dialog" style="margin-right: 10px;"><mat-icon>close</mat-icon></button>
  Modifier le document "{{title}}"</mat-toolbar>
<div mat-dialog-content>
  <div fxLayout='row' fxLayoutAlign='start center' style="margin-left: 15px;">
    <mat-icon fxFlexAlign='center' color="primary">arrow_right</mat-icon>
    <h4 fxFlexAlign='center'>Renommer en :</h4>
  </div>
  <mat-form-field style="width: 80%; margin: auto; display: block;">
    <input matInput [(ngModel)]="file.name">
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout='row' fxLayoutAlign='end center'>
  <button mat-raised-button [mat-dialog-close]="file" cdkFocusInitial color="primary">Valider</button>
</div>
