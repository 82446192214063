import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from './shared.module';
import { PublicModule } from './public.module';

import { AuthenticationGuard } from '@app/guards/authentication.guard';
import { PermissionGuard } from '@app/guards/permission.guard';
import { Role } from '@app/models/user';
import { VariousGuard } from '@app/guards/various.guard';
import { TotpGuard } from '@app/guards/totp.guard';

import { UserEditComponent } from '@app/components/_public/user-edit/user-edit.component';
import { ChildEditComponent } from '@app/components/_user/children/edit/child-edit.component';
import { PreinscriptionEditComponent } from '@app/components/_user/preinscription/edit/preinscription-edit.component';
import { UserReservationEditComponent } from '@app/components/_user/reservation/edit/edit.component';
import { PlanningSelectDialogComponent } from '@app/components/_user/reservation/planning/select-dialog/select-dialog.component';
import { AccountComponent } from '@app/components/_user/account/account.component';
import { UserInfoComponent } from '@app/components/_user/user-info/user-info.component';
import { AssmatInfoComponent } from '@app/components/_user/assmat-info/assmat-info.component';
import { PreinscriptionListComponent } from '@app/components/_user/preinscription/list/preinscription-list.component';
import { ChildrenListComponent } from '@app/components/_user/children/list/children-list.component';
import { FactureComponent } from '@app/components/_user/facture/facture.component';
import { ReservationsComponent } from '@app/components/_user/reservation/reservations.component';
import { PaiementComponent } from '@app/components/_user/facture/paiement/paiement.component';
import { FoyerComponent } from '@app/components/_user/foyer/foyer.component';
import { FoyerEditComponent } from '@app/components/_user/foyer/foyer-edit/foyer-edit.component';
import { ConjointEditComponent } from '@app/components/_user/conjoint-edit/conjoint-edit.component';
import { PlanningDetailsDialogComponent } from '@app/components/_user/reservation/planning/details-dialog/details-dialog.component';
import { GedComponent } from '@app/components/_user/ged/ged.component';
import { PresenceRepeatComponent } from '@app/components/_user/reservation/planning/presence-repeat/presence-repeat.component';
import { ReservationPanierComponent } from '@app/components/_user/reservation/panier/panier.component';
import { MikadoNewPresenceComponent } from '@app/components/_user/reservation/planning/mikado-new-presence/mikado-new-presence.component';
import { AssmatPointageComponent } from '@app/components/_user/assmat-pointage/assmat-pointage.component';
import { TicketDematComponent } from '@app/components/_user/ticket-demat/ticket-demat.component';
import { AchatTicketComponent } from '@app/components/_user/ticket-demat/achat-ticket/achat-ticket.component';
import { EditionListComponent } from '@app/components/_user/edition/edition-list.component';
import { LasidoPreinscriptionComponent } from '@app/components/_user/lasido-preinscription/lasido-preinscription.component';
import { LasidoPreinscriptionEditComponent } from '@app/components/_user/lasido-preinscription/edit/edit.component';
import { AssmatInfoEditComponent } from '@app/components/_user/assmat-info/assmat-info-edit/assmat-info-edit.component';
import { AssmatAvailabilityComponent } from '@app/components/_user/assmat-info/assmat-availability/assmat-availability.component';
import { ContratAccueilMentaloListComponent } from '@app/components/_user/contrats-accueil-mentalo/contrat-accueil-mentalo-list/contrat-accueil-mentalo-list.component';
import { ContratAccueilMentaloEditComponent } from '@app/components/_user/contrats-accueil-mentalo/contrat-accueil-mentalo-edit/contrat-accueil-mentalo-edit.component';
import { InscriptionAnimationsComponent } from '@app/components/_user/animations/inscription-animations/inscription-animations.component';
import { InscriptionReunionsComponent } from '@app/components/_user/reunions/inscription-reunions/inscription-reunions.component';
import { ListAnimationsComponent } from '@app/components/_user/animations/list-animations/list-animations.component';
import { ListReunionsComponent } from '@app/components/_user/reunions/list-reunions/list-reunions.component';
import { PresenceListComponent } from '@app/components/_user/reservation/planning/presence-list/presence-list.component';
import { LasidoInscriptionComponent } from '@app/components/_user/lasido-inscription/lasido-inscription.component';
import { RenouvellementInscriptionComponent } from '@app/components/_user/lasido-inscription/renouvellement-inscription/renouvellement-inscription.component';
import { CogitoPreinscriptionComponent } from '@app/components/_user/cogito-preinscription/cogito-preinscription.component';
import { EditCogitoPreinscriptionComponent } from '@app/components/_user/cogito-preinscription/edit-cogito-preinscription/edit-cogito-preinscription.component';
import { ListAnimationsReunionsComponent } from '@app/components/_user/list-animations-reunions/list-animations-reunions.component';
import { EditPhotoComponent } from '@app/components/_user/children/edit-photo/edit-photo.component';
import { CogitoInscriptionsComponent } from '@app/components/_user/cogito-inscriptions/cogito-inscriptions.component';
import { EditCogitoInscriptionComponent } from '@app/components/_user/cogito-inscriptions/edit-cogito-inscription/edit-cogito-inscription.component';
import { MandatsSepaComponent } from '@app/components/_user/mandats-sepa/mandats-sepa.component';
import { MandatSepaEditComponent } from '@app/components/_user/mandats-sepa/mandat-sepa-edit/mandat-sepa-edit.component';
import { MessagerieComponent } from '@app/components/_user/messagerie/messagerie.component';
import { FilePreviewComponent } from '@app/components/_user/ged/file-preview/file-preview.component';
import { FileUploadDialogComponent } from '@app/components/_user/ged/file-upload-dialog/file-upload-dialog.component';
import { DocDetailsComponent } from '@app/components/_user/ged/doc-details/doc-details.component';
import { MandatSepaPdfDialogComponent } from '@app/components/_user/mandats-sepa/mandat-sepa-pdf-dialog/mandat-sepa-pdf-dialog.component';
import { DiaboloInscriptionsComponent } from '@app/components/_user/diabolo-inscriptions/diabolo-inscriptions.component';
import { EditDiaboloInscriptionsComponent } from '@app/components/_user/diabolo-inscriptions/edit-diabolo-inscriptions/edit-diabolo-inscriptions.component';
import { UpdatePasswordComponent } from '@app/components/_user/update-password/update-password.component';
import { TotpManageComponent } from '@app/components/_user/totp-manage/totp-manage.component';



const userRoutes: Routes = [
  {
    path: '',
    canActivateChild: [AuthenticationGuard, VariousGuard],
    data: { roles: [Role.User], notForManager: true, title: 'menu.account' },
    children: [
      // Account index
      { path: '', pathMatch: 'full', component: AccountComponent, data: { title: 'menu.account' } },

      // Generic paths for every component
      { path: 'user', component: UserInfoComponent },
      // Personal informations
      {
        path: 'user', data: { title: 'Utilisateur', disableAccountSwitch: true },
        children: [
          { path: 'edit', component: UserEditComponent, canActivate: [PermissionGuard], data: { perm: 'account_edit' } },
          // Keep readonly access to part of account data (form disabled)
          { path: ':step', component: UserEditComponent },
        ]
      },
      // handle possible former similar URLs for same component
      { path: 'profile', redirectTo: 'user' },
      { path: 'personal', redirectTo: 'user' },

      // Données du compte Famille
      // Foyer
      { path: 'foyer', component: FoyerComponent, canActivate: [PermissionGuard], data: { perm: 'foyer_access' } },
      {
        path: 'foyer', data: { title: 'Foyer', disableAccountSwitch: true }, children: [
          { path: 'edit', component: FoyerEditComponent, canActivate: [PermissionGuard], data: { perm: 'foyer_edit' } },
          { path: ':step', component: FoyerEditComponent },
        ]
      },

      // Conjoint
      { path: 'conjoint', redirectTo: 'foyer', pathMatch: 'full' },
      {
        path: 'conjoint', data: { title: 'Conjoint', disableAccountSwitch: true }, children: [
          { path: 'edit', component: ConjointEditComponent, canActivate: [PermissionGuard], data: { perm: 'foyer_edit' } },
          { path: ':step', component: ConjointEditComponent },
        ]
      },
      // Children
      { path: 'children', component: ChildrenListComponent, canActivate: [PermissionGuard], data: { perm: 'child_access' } },
      {
        path: 'children', data: { title: 'Enfants', disableAccountSwitch: true }, children: [
          {
            path: 'add', component: ChildEditComponent, canActivate: [PermissionGuard],
            data: { title: 'Nouvel enfant', perm: 'child_create' },
          },
          {
            path: 'edit/:id', component: ChildEditComponent, canActivate: [PermissionGuard],
            data: { perm: 'child_edit' },
          },
          // Keep readonly access to part child data (form disabled)
          { path: 'edit/:id/:step', component: ChildEditComponent }
        ]
      },

      // Facturation - Paiement
      { path: 'factures', component: FactureComponent, canActivate: [PermissionGuard, TotpGuard], data: { perm: 'factures_access', totpRestrict: 'restrictFactures' } },
      { path: 'paiement/:regie', component: PaiementComponent, canActivate: [TotpGuard], data: { disableAccountSwitch: true, totpRestrict: 'restrictFactures' } },

      // Tickets dématarialisé
      { path: 'tickets', component: TicketDematComponent, canActivate: [PermissionGuard], data: { disableAccountSwitch: true, perm: 'tickets_access' } },
      {
        path: 'tickets', data: { title: 'Mes tickets', disableAccountSwitch: true },
        children: [
          { path: 'achat', component: AchatTicketComponent, data: { title: 'Achat de tickets', disableAccountSwitch: true } },
        ]
      },

      //Mandats SEPA
      { path: 'mandats-sepa', component: MandatsSepaComponent, canActivate: [PermissionGuard, TotpGuard], data: { disableAccountSwitch: true, perm: 'mandat_sepa_access', totpRestrict: 'restrictSEPA' } },
      {
        path: 'mandats-sepa',
        canActivateChild: [TotpGuard],
        data: { title: "Mandats SEPA", totpRestrict: 'restrictSEPA' },
        children: [
          { path: 'new', component: MandatSepaEditComponent, canActivate: [PermissionGuard], data: { perm: 'mandat_sepa_create', title: "Nouveau mandat SEPA" } },
          { path: ':id', component: MandatSepaEditComponent, canActivate: [PermissionGuard], data: { perm: 'mandat_sepa_edit', title: "Modification d'un mandat SEPA" } },
        ]
      },
      { path: 'documents', component: GedComponent, canActivate: [PermissionGuard, TotpGuard], data: { perm: 'document_access', totpRestrict: 'restrictGED' } },
      { path: 'editions', component: EditionListComponent, canActivate: [PermissionGuard], data: { disableAccountSwitch: true, perm: 'editions_access' } },
      { path: 'messagerie', component: MessagerieComponent, canActivate: [PermissionGuard], data: { title: 'Messagerie', perm: 'messagerie_access' } },

      { path: 'update-password', component: UpdatePasswordComponent, canActivate: [VariousGuard], data: { title: 'Modification du mot de passe', notFromOIDC: true, notForManager: true } },
      { path: 'totp-manage', component: TotpManageComponent, data: { title: 'totp.title' } },

      // Pour Diabolo OU Mikado
      { path: 'reservations', component: ReservationsComponent },

      // Diabolo : reservations
      {
        path: 'reservations', data: { title: 'Réservations', disableAccountSwitch: true }, children: [
          { path: 'panier', component: ReservationPanierComponent },
          { path: 'new', component: UserReservationEditComponent, canActivate: [PermissionGuard] },
          { path: ':id', component: UserReservationEditComponent }
        ]
      },

      // Diabolo - Inscriptions
      { path: 'diabolo-inscriptions', component: DiaboloInscriptionsComponent, canActivate: [PermissionGuard], data: { perm: 'diabolo_inscriptions_access' } },
      {
        path: 'diabolo-inscriptions', data: { title: "diabolo.title_fil_ariane" }, children: [
          { path: 'new', component: EditDiaboloInscriptionsComponent, canActivate: [PermissionGuard], data: { perm: 'diabolo_inscriptions_create', title: "diabolo.new_inscription" } },
          { path: ':id', component: EditDiaboloInscriptionsComponent, canActivate: [PermissionGuard], data: { perm: 'diabolo_inscriptions_edit', title: "diabolo.edit_inscription" } },
        ]
      },

      // Preinscriptions Mikado
      { path: 'preinscription', component: PreinscriptionListComponent, canActivate: [PermissionGuard], data: { perm: 'preinscription_access' } },
      {
        path: 'preinscription', data: { title: 'Pré-inscriptions', disableAccountSwitch: true },
        children: [
          {
            path: 'add', component: PreinscriptionEditComponent, canActivate: [PermissionGuard],
            data: { title: 'Nouvelle pré-inscription', perm: 'preinscription_create' }
          },
          {
            path: 'edit/:id', component: PreinscriptionEditComponent, canActivate: [PermissionGuard],
            data: { perm: 'preinscription_edit' },
          },
          { path: 'edit/:id/:step', component: PreinscriptionEditComponent }
        ]
      },


      /****************************************************************
       * Lasido
       ****************************************************************/
      // Lasido - Preinscriptions
      { path: 'lasido-preinscriptions', component: LasidoPreinscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'lasido_access' } },
      {
        path: 'lasido-preinscriptions', data: { title: 'Pré-inscriptions Lasido' }, children: [
          { path: 'new', component: LasidoPreinscriptionEditComponent, data: { title: 'Nouvelle pré-inscription' } },
          { path: ':id', component: LasidoPreinscriptionEditComponent, data: { title: "Modification d'un pré-inscription" } },
        ]
      },

      // Lasido - Inscriptions-renouvellement
      { path: 'lasido-inscriptions', component: LasidoInscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'lasido_renewal_inscriptions_access' } },
      {
        path: 'lasido-inscriptions', data: { title: 'Inscriptions Lasido' }, children: [
          { path: ':id', component: RenouvellementInscriptionComponent, data: { title: 'Renouvellement inscription' } },
        ]
      },


      /****************************************************************
       * Cogito
       ****************************************************************/
      // Cogito - Preinscriptions
      { path: 'cogito-preinscriptions', component: CogitoPreinscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_access' } },
      {
        path: 'cogito-preinscriptions', data: { title: "cogito.title_preinscription_fil_ariane" },
        children: [
          { path: 'new', component: EditCogitoPreinscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_create', title: "cogito.new_preinscription" } },
          { path: ':id', component: EditCogitoPreinscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_edit', title: "cogito.edit_preinscription" } },
        ]
      },
      // Cogito - Inscriptions
      { path: 'cogito-inscriptions', component: CogitoInscriptionsComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_inscriptions_access' } },
      {
        path: 'cogito-inscriptions', data: { title: "cogito.title_inscription_fil_ariane" },
        children: [
          { path: 'new', component: EditCogitoInscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_inscriptions_create', title: "cogito.new_inscription" } },
          { path: ':id', component: EditCogitoInscriptionComponent, canActivate: [PermissionGuard], data: { perm: 'cogito_inscriptions_edit', title: "cogito.edit_inscription" } },
        ]
      },

      /****************************************************************
       * Compte Assmat 
       ****************************************************************/
      { path: 'assmat', component: AssmatInfoComponent, canActivate: [PermissionGuard], data: { perm: 'enabled_assmat_account' } },
      { path: 'contrat-accueil-mentalo', component: ContratAccueilMentaloListComponent, canActivate: [PermissionGuard], data: { perm: 'contrat_accueil_access' } },
      { path: 'animations', component: ListAnimationsComponent, canActivate: [PermissionGuard], data: { perm: 'animations_access' } },
      { path: 'reunions', component: ListReunionsComponent, canActivate: [PermissionGuard], data: { perm: 'reunions_access' } },
      { path: 'assmat-pointage', component: AssmatPointageComponent, canActivate: [PermissionGuard], data: { disableAccountSwitch: true, perm: 'planning_pointage_access' } },
      //Assmat info
      {
        path: 'assmat', data: { title: 'Assistant maternel', disableAccountSwitch: true }, children: [
          { path: 'availability', component: AssmatAvailabilityComponent, canActivate: [PermissionGuard], data: { perm: 'disponibilites_edit', title: "Disponibilités" } },
          { path: 'edit', component: AssmatInfoEditComponent, canActivate: [PermissionGuard], data: { perm: 'informations_assmat_edit' } },
          { path: ':step', component: AssmatInfoEditComponent },
        ]
      },

      //Contrats accueil mentalo
      {
        path: 'contrat-accueil-mentalo', data: { title: 'Contrat d\'accueil' }, children: [
          {
            path: 'add', component: ContratAccueilMentaloEditComponent, canActivate: [PermissionGuard],
            data: { title: "Nouveau contrat d'accueil", perm: 'contrat_accueil_create' }
          },
          {
            path: 'edit/:id', component: ContratAccueilMentaloEditComponent, canActivate: [PermissionGuard],
            data: { title: "Modification contrat d'accueil", perm: 'contrat_accueil_edit' }
          },
          {
            path: 'edit/:id/:step', component: ContratAccueilMentaloEditComponent
          }
        ]
      },

      {
        path: 'animations', data: { title: 'Animations' }, children: [
          {
            path: 'inscriptions', component: InscriptionAnimationsComponent, canActivate: [PermissionGuard],
            data: { title: "Inscriptions aux animations", perm: "animations_add" }
          }
        ]
      },

      {
        path: 'reunions', data: { title: 'Réunions' }, children: [
          {
            path: 'inscriptions', component: InscriptionReunionsComponent, canActivate: [PermissionGuard],
            data: { title: "Inscriptions aux réunions", perm: "reunions_add" }
          }
        ]
      },


    ]
  }
];

@NgModule({
  declarations: [
    ChildEditComponent,
    PreinscriptionEditComponent,
    UserReservationEditComponent,
    PlanningSelectDialogComponent,
    PlanningDetailsDialogComponent,
    MikadoNewPresenceComponent,
    PresenceRepeatComponent,
    AccountComponent,
    PaiementComponent,
    FoyerComponent,
    FoyerEditComponent,
    ConjointEditComponent,
    GedComponent,
    ReservationPanierComponent,
    AssmatPointageComponent,
    TicketDematComponent,
    AchatTicketComponent,
    LasidoPreinscriptionComponent,
    LasidoPreinscriptionEditComponent,
    AssmatInfoEditComponent,
    AssmatInfoComponent,
    AssmatAvailabilityComponent,
    ContratAccueilMentaloListComponent,
    ContratAccueilMentaloEditComponent,
    InscriptionAnimationsComponent,
    InscriptionReunionsComponent,
    ListAnimationsComponent,
    ListReunionsComponent,
    PresenceListComponent,
    LasidoInscriptionComponent,
    RenouvellementInscriptionComponent,
    CogitoPreinscriptionComponent,
    EditCogitoPreinscriptionComponent,
    ListAnimationsReunionsComponent,
    EditPhotoComponent,
    CogitoInscriptionsComponent,
    EditCogitoInscriptionComponent,
    MandatsSepaComponent,
    MandatSepaEditComponent,
    FilePreviewComponent,
    FileUploadDialogComponent,
    DocDetailsComponent,
    MandatSepaPdfDialogComponent,
    DiaboloInscriptionsComponent,
    EditDiaboloInscriptionsComponent,
    TotpManageComponent
  ],
  imports: [
    SharedModule,
    PublicModule,
    RouterModule.forChild(userRoutes)
    // RouterModule.forRoot(userRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class UserModule { }
