import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @HostBinding('class.app-loader') loaderClass = true;

  @Input() size = 3; // The most common case is a quite big loader
  @Input() message: string;
  @Input() color: ThemePalette = 'accent';

  @Input() suspend; // isLoading = true by default, setting this to true will stop the loader

  sizeInPx: number;

  constructor() { }

  ngOnInit() {
    this.sizeInPx = Math.floor(32 * this.size);
  }

}
