import { Pipe, PipeTransform } from '@angular/core';
import { CustomStyleService } from '@app/services/custom-style.service';

@Pipe({
  name: 'customColor'
})
export class CustomColorPipe implements PipeTransform {

  constructor(
    private customStyleService: CustomStyleService
  ) { }

  transform(value: any, ...args: any[]): any {
    return this.customStyleService.parseCustomColor(value);
  }

}
