import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-document-picker',
  templateUrl: './document-picker.component.html',
  styleUrls: ['./document-picker.component.scss']
})
export class DocumentPickerComponent implements OnInit {

  type: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit() {
    if (this.data && this.data.type) {
      this.type = this.data.type;
    }
  }
}
