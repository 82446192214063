<ng-container
  *ngIf="activity.imgPreview || (activity.textPreview && activity.textPreview !== commentForMonaco && activity.textPreview !== commentForTinyMCE)">
  <img *ngIf="activity.imgPreview" mat-card-image src="{{ activity.imgPreview }}" class="preview-image"
    [ngStyle]="{'object-fit': modeAffichageActivite === 'periode' ? 'cover' : 'contain', 'max-height.vh': modeAffichageActivite === 'periode' ? '100' : '30'}">
  <div *ngIf="activity.textPreview" class="preview-text" [innerHTML]="activity.textPreview|safe:'html'"></div>
</ng-container>

<ng-container
  *ngIf="!activity.imgPreview && (!activity.textPreview || activity.textPreview === commentForMonaco || activity.textPreview === commentForTinyMCE)">
  <img *ngIf="preview.image" src="{{ preview.image }}" class="preview-image"
    [ngStyle]="{'object-fit': modeAffichageActivite === 'periode' ? 'cover' : 'contain', 'max-height.vh': modeAffichageActivite === 'periode' ? '100' : '30'}">
  <p class="preview-text">{{ preview.text }}</p>
</ng-container>
