import { Component, OnInit } from '@angular/core';
import { ExtendedFormlyFieldConfig } from '@app/forms/config/form-model';
import { FieldArrayType } from '@ngx-formly/core';
import { animate, state, style, transition, trigger, group } from '@angular/animations';

import { FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-array-from-default-value',
  styles: [`
  .bloc-form {margin: 5px 0 !important;}
  .bloc-form-content{padding:0 16px !important;}
  .no-padding{padding:0 !important;}
  mat-icon{ margin-right:10px;}
  `],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ height: '0', opacity: 0 }),
        animate('200ms ease-in-out', style({ height: '*', opacity: 1 })),
      ])
    ])
  ],
  template: /*html*/`

<div class="display-flex-row flex-wrap" *ngIf="ready"  @enterAnimation>
  <div *ngFor="let dv of defaultValue; let i = index;" class="{{defaultValue[i]?.className}}" >
  
    <div *ngIf="dv?.type !== 'message'; else plainMessage" class="" [ngClass]="{'bloc-form border': displayAsBlock}">
      <div class="bloc-form-title-bar" *ngIf="displayTitle">
        <mat-icon>chevron_right</mat-icon>
        <span>
          {{ dv?.templateOptions?.label || dv?.configTitle }}
          <span class="mat-form-field-required-marker" *ngIf="dv?.templateOptions.required">*</span>

        </span>
      </div>
      <div class="bloc-form-content" [ngClass]="{'no-padding': !displayAsBlock}">

        <formly-group style="width:100%;" [field]="findMatchingFieldInFormGroup(i)">
        </formly-group>

      </div>
    </div>
    <ng-template #plainMessage>
      <!--  We now call directly our message component because formly don't display it anymore in this case :-/ (since upgrade ^5.12.7) !--> 
      <app-formly-message [field]="dv"></app-formly-message> 
    </ng-template>
  </div>
</div>

`,
})


export class ArrayFromDefaultValueComponent extends FieldArrayType implements OnInit {
  displayAsBlock: boolean;
  displayTitle: boolean;
  defaultValue: any[];
  rowConf: ExtendedFormlyFieldConfig;
  ready = false;

  constructor() {
    super();
  }

  onPopulate(field: FormlyFieldConfig) {
    super.onPopulate(field);
    if (this.ready) { // for admin mode
      setTimeout(() => this.initListElements());
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.rowConf = this.field.fieldArray.fieldGroup as ExtendedFormlyFieldConfig;
      this.displayAsBlock = this.field.templateOptions.displayAsBlock;
      this.displayTitle = this.field.templateOptions.displayTitle;
      this.initListElements();
      this.ready = true;
    });
  }

  initListElements() {

    this.field.fieldGroup = [...this.field.fieldGroup.filter(listeElement => !listeElement.model.hide
      && listeElement.model.key !== 'message' && !listeElement.model.key.endsWith('_message'))];
    this.defaultValue = [...this.field.defaultValue.filter(row => !row.hide)];

    this.defaultValue.forEach((lineDefaultValue, rowIndex) => {

      lineDefaultValue.templateOptions = lineDefaultValue?.templateOptions || {};
      const listeElement = this.findMatchingFieldInFormGroup(rowIndex);
      if (lineDefaultValue.type === 'message') {
        // Disable the formcontrol generated by the fieldArray beacause it's only a message, and it nvever has value
        // and since upgrade formly ^5.12.7, this formControl is always "Invalid"
        this.formControl.at(rowIndex).disable()

        // actually we should use a more general option meaning that the line is standalone, out of the "fieldArray" mechanism
      } else if (!listeElement.model.hide && !lineDefaultValue.hide) {

        listeElement.className = listeElement.model.className;
        listeElement.templateOptions = listeElement.model.templateOptions || listeElement.templateOptions || {};
        listeElement.templateOptions.label = listeElement.model.label || listeElement.model.configTitle;

        this.updateFields(listeElement.fieldGroup, listeElement.model, lineDefaultValue);
      }
    });

  }

  findMatchingFieldInFormGroup(defaultValueSearchedIndex: number) {
    let formFieldIndex = 0;
    for (let i = 0; i < this.defaultValue.length; i++) {
      if (i === defaultValueSearchedIndex) {
        return this.field.fieldGroup[formFieldIndex];
      }
      if (this.defaultValue[i].type !== 'message') {
        formFieldIndex++;
      }
    }
    return null;
  }

  updateFields(fields: ExtendedFormlyFieldConfig[], model: any, lineDefaultValue) {
    fields.forEach((field, fieldIndex) => {

      if (!this.rowConf[fieldIndex].hide) {

        field.templateOptions = field.templateOptions || {};
        field.model.templateOptions = field.model.templateOptions || {};
        model.templateOptions = model.templateOptions || {};

        field.templateOptions.disabled = field.readOnly;

        if (field.fields) { this.updateFields(field.fields, model, lineDefaultValue); }
        if (field.fieldGroup) { this.updateFields(field.fieldGroup, model, lineDefaultValue); }

        const elementConfig = this.rowConf[fieldIndex];

        // Label du champ
        if (elementConfig.labelFromModel) {
          if (this.displayTitle) {
            field.templateOptions.label = null;
          } else {
            field.templateOptions.label = lineDefaultValue.templateOptions.label || lineDefaultValue.configTitle;
          }

        } else if (!field.templateOptions.label) {
          field.templateOptions.label = field.configTitle;
        }

        // Required
        if (elementConfig.requiredFromModel) {
          field.templateOptions.required = lineDefaultValue.templateOptions.required;
        }
        if (elementConfig.readOnlyFromModel) {
          field.templateOptions.disabled = lineDefaultValue.readOnly;
        }

        if (elementConfig.optionsFromModel && lineDefaultValue.templateOptions.options) {
          field.templateOptions.options = lineDefaultValue.templateOptions.options
            .filter(opt => !opt.hide);
        }

        if (elementConfig.messageContentFromModel && field.type === 'message') {
          field.templateOptions.message = lineDefaultValue.templateOptions[field.key as string];
        }

        //Heures
        if (field.type === 'heure') {
          field.templateOptions.maxHour = lineDefaultValue.templateOptions.maxHour;
          field.templateOptions.minHour = lineDefaultValue.templateOptions.minHour;
        }
      }
    });
  }
}
