import { Component, OnInit, Input } from '@angular/core';
import { ActivityWithDay, PlanningService } from '@app/services/planning.service';
@Component({
  selector: 'app-activity-preview',
  templateUrl: './activity-preview.component.html',
  styleUrls: ['./activity-preview.component.scss']
})
export class ActivityPreviewComponent implements OnInit {

  @Input() modeAffichageActivite: string;
  @Input() activity: ActivityWithDay;
  preview: { image: string, text: string };

  commentForMonaco: string = '<!-- editorType:Monaco -->';
  commentForTinyMCE: string = '<!-- editorType:TinyMCE -->';

  constructor(
    private planningService: PlanningService
  ) { }

  ngOnInit() {
    this.preview = this.planningService.getActivityPreview(this.activity);
  }
}
