import {
  Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, ElementRef, ViewChildren, QueryList, AfterViewInit
} from '@angular/core';
import { PeriodeTreeItem } from '@app/models/periode';
import { PeriodeService, weekDays } from '@app/services/periode.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SnackbarService, TypeSnackbar } from '@app/services/snackbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { startWith, takeUntil, tap } from 'rxjs/operators';
import { Subject, forkJoin, merge, of, fromEvent } from 'rxjs';
import { PlatformService, FamilyService, FacturationService } from '@app/services';
import { Reservation, ReservationError, ReservationPresence } from '@app/models/reservation';
import { DATETIME_FORMAT, PlanningService } from '@app/services/planning.service';
import { PermissionService } from '@app/services/permission.service';
import { ConfirmDialogComponent } from '@app/components/_common/confirm-dialog/confirm-dialog.component';

import { ReservationPlanningComponent } from '../planning/planning.component';
import { PlanningData } from '../planning/planning-data';
import { Facture } from '@app/models/facturation';
import { computePresenceStatus, ReservationService } from '@app/services/reservation.service';
import moment from 'moment';
import { Consumer } from '@app/models/consumer';
import { MatSelect } from '@angular/material/select';
import { Family } from '@app/models/family';
import { HeaderService } from '@app/services/header.service';
import { TranslateService } from '@ngx-translate/core';

interface SubmitResult {
  creation?: boolean;
  validation?: boolean;
  errors?: ReservationError[];
  traces?: string[];
  facture?: any;
  messages?: string[];

  reservation: Reservation;
  attentes?: ReservationError[];
}

interface MessagePerso {
  affichagePlanning?: string[];
  validationPlanning?: string[];
  filtragePeriode?: string[];
}

@Component({
  selector: 'app-diabolo-reservation-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false, showError: true }
  }]
})
export class UserReservationEditComponent implements OnInit, OnDestroy, AfterViewInit {

  // Must be a string of form "idPeriode-idInscription"
  ids = this.route.snapshot.paramMap.get('id');
  isEdit = !!this.ids;

  data: PlanningData;

  get currentReservation() { return this.data.currentReservation; }
  get currentPeriode() { return this.data.currentPeriode; }

  get unsavedChanges() { return this.data.currentReservation?.presences?.length; }

  sumReservation: Reservation;
  hasAttentes: boolean;
  presencesCount = 0;

  globalsErrors: string[]; // HTML safe (from back)
  messagesPersoFromPrograms: MessagePerso = {}; // HTML safe (from back)
  sumErrorPresences: ReservationPresence[]; // presences with some error

  // Each step requires a FormGroup (for validation)
  consumerFormGroup: UntypedFormGroup;
  periodeFormGroup: UntypedFormGroup;
  reservationForm: UntypedFormGroup;

  currentUsager: Consumer;

  inscriptionError: string;
  inscriptionErrorSteps: { stepName: string, label: string, link?: string }[];
  // inscriptionErrorVaccins: string[];

  // PeriodeTree stuff
  periodeTreeSource = new MatTreeNestedDataSource<any>();
  periodeTreeControl = new NestedTreeControl<PeriodeTreeItem>(this.periodeService.getPeriodeTreeNodeChildren);
  periodeTreeAllExpanded: boolean;

  // Loading
  loadingTree = false;
  loadingPeriodeInfo = false;
  saving = false;
  hasError = false;
  alertBigReservation = false;

  facture: Facture;

  weekDays = weekDays;

  pageTitle: string;

  scrollPosition = 0;

  showMessagePausePaiement = false;

  step = 0; // First step, default
  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild(ReservationPlanningComponent) planningComponent: ReservationPlanningComponent;
  @ViewChild('optionsPanel', { static: true, read: ViewContainerRef }) optionsPanelContainer: ViewContainerRef;

  @ViewChildren('errorList', { read: ElementRef }) errorList: QueryList<ElementRef>;

  optionsPanelOpened = false;
  lastPanelToggle: number;

  isActivityMode: boolean;

  onDestroy$ = new Subject();

  constructor(
    public platformService: PlatformService,
    private familyService: FamilyService,
    private periodeService: PeriodeService,
    private planningService: PlanningService,
    private formBuilder: UntypedFormBuilder,
    private snackbar: SnackbarService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private reservationService: ReservationService,
    private facturationService: FacturationService,
    private permService: PermissionService,
    private headerService: HeaderService,
    private translateService: TranslateService
  ) {
    this.data = new PlanningData();
  }

  ngOnInit() {

    if (!this.permService.hasPermission('reservation_diabolo') && !this.permService.hasPermission('reservation_mikado')) {
      this.snackbar.error('Les réservations ne sont pas activées');
      this.router.navigate(['/']);
      return;
    }

    // Else we're in "update" mode and there's no choice on child / periode, so no form to create
    if (!this.ids) {
      this.initUserForm(); // step 1
      this.initPeriodeForm(); // step 2
    }

    this.initReservationForm(); // step 3

    // We're always in "create" mode
    this.data.setCurrentReservation({ id: 'new', presences: [] } as Reservation);

    this.data.onPresenceUpdate.subscribe(_ => {

      this.reservationForm.get('hasPresences').setValue(this.currentReservation?.presences?.length > 0);
      if (this.step < 3) { // don't reset summary if we have passed the planning
        this.sumReservation = null;
      }
    });

    this.familyService.currentFamilyReadyOnce$.subscribe(f => this.loadFamilyData(f));
    this.data.onChangePeriode.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(_ => {
      if (this.currentPeriode) {
        this.isActivityMode = this.data.currentPeriode.modeSelection === 'activite';

        const today = moment().format(DATETIME_FORMAT);
        // Error if the Periode is closed
        if (!this.currentPeriode.enabled || today < this.currentPeriode.saisieDebut || today > this.currentPeriode.saisieFin) {
          this.snackbar.error('reservation.periode.error_closed');
          this.router.navigateByUrl('/account/reservations');
          return;
        }
      }
    });

    this.data.onChangeUsager.subscribe(_ => this.currentUsager = this.data.findConsumer(this.currentReservation.idConsumer));

    // Update page Title when related stuff changes
    merge(this.data.onChangeUsager, this.data.onChangePeriode).pipe(
      startWith({}),
      takeUntil(this.onDestroy$)
    ).subscribe(_ => this.headerService.setCurrentPageTitle(this.buildTitle()));
  }

  ngAfterViewInit() {
    this.stepper.selectionChange.subscribe((event: StepperSelectionEvent) => {
      if (event.selectedStep.state === 'recap' && !this.sumReservation) {
        this.loadSummary();
      }
    });
  }

  loadFamilyData(f: Family | number) {
    this.reservationService.loadPlanningDataProgressive(f, this.data).subscribe();

    this.loadConsumersWithInscriptions(f).subscribe(_ => {
      this.loadPeriodeForExisting();

      if (this.data.consumers.length === 1 && !this.isEdit) {
        this.consumerFormGroup.get('consumer').setValue(this.data.consumers[0].id);
        this.onChangeUsager(this.data.consumers[0].id);
      }
    });
  }

  loadConsumersWithInscriptions(f: Family | number) {
    return forkJoin([
      this.familyService.getFamilyConsumers(f),
      // Have to load every inscriptions, with a type on each
      this.reservationService.getFamilyInscriptions(f)
    ]).pipe(tap(([consumers, inscriptions]) => {
      this.data.inscriptions = inscriptions;
      consumers.forEach(c => c.inscriptions = inscriptions.filter(i => (i.idEnfant === c.idEnfant && i.idAdulte === c.idAdulte)));
      this.data.consumers = consumers.filter(c => c.inscriptions?.length);
    }));
  }

  loadPeriodeForExisting() {
    if (this.ids) {
      const idParts = this.ids.split('-');
      const idPeriode = idParts[0];
      const inscription = this.data.findInscription(+idParts[1]);

      if (!idPeriode || !inscription) {
        this.snackbar.error(`Elément(s) de réservation introuvable(s) : inexistant(s) ou non associé(s) à la famille en cours`);
        this.router.navigate(['/account/reservations']);
        return;
      }

      this.loadInscriptionPeriode(idPeriode, inscription.id).subscribe(periode => {
        // Copy unchangeable elements from "current reservation"
        this.data.currentReservation.idInscription = inscription.id;
        this.data.currentReservation.idConsumer = Consumer.buildIdConsumer(inscription.idEnfant, inscription.idAdulte);
        this.data.setCurrentPeriode(periode);
      }, err => this.router.navigate(['/account/reservations']));

      // Was meant to place on first presence (by date) at Planning start @TODO: check if this (would) work ?
      // this.planningOptions.defaultDate = originReservation.presences[0].date;
    }
  }

  loadInscriptionPeriode(idPeriode: string | number, idInscription: number) {
    this.messagesPersoFromPrograms.affichagePlanning = [];

    this.data.loadingPeriode = true;
    return this.periodeService.getInscriptionPeriode(idPeriode, idInscription).pipe(
      tap(res => {
        this.data.loadingPeriode = false;

        //Get errors Vaccins obligatoire
        if (res.error && res.errorVaccins.length > 0) {
          const vaccinInLi = res.errorVaccins.map(vacc => `<li>${vacc}</li>`).join(' ');
          const errMsg = `${this.translateService.instant('reservation.form_incomplete.' + res.error)}
            <ul>${vaccinInLi}</ul>`

          this.snackbar.error(errMsg);
          throw new Error(errMsg);
        }
        // Get errors & traces from programs
        this.displayDebugTraces(res.traces);

        if (res.messages && !res.errors) {
          this.messagesPersoFromPrograms.affichagePlanning = res.messages;
        }

        if (res.errors && res.errors.length > 0) {
          this.messagesPersoFromPrograms.affichagePlanning = [];
          const errMsg = `Erreur :
            <ul>
            ${res.errors.map(e => `<li>${e.errorMessage}</li>`).join()}
            </ul>
          `;
          this.snackbar.error(errMsg);
          throw new Error(errMsg);
        }
      })
    );
  }

  initUserForm() {
    this.consumerFormGroup = this.formBuilder.group({
      consumer: ['', Validators.required]
    });

    // @TODO: review this (pass object instead of ID, move to option (click) and prevent default (else the option changes on UI))
  }

  initPeriodeForm() {
    this.periodeFormGroup = this.formBuilder.group({
      inscription: ['', Validators.required],
      periode: ['', Validators.required]
    });
  }

  initReservationForm() {
    this.reservationForm = this.formBuilder.group({
      hasPresences: ['', Validators.required]
    });
  }

  askForConfirm(message: string) {
    const ref = this.dialog.open(ConfirmDialogComponent, {
      data: { message }
    });

    this.platformService.adaptDialogToScreen(ref);

    return ref.afterClosed();
  }

  onChangeUsager(idConsumer: string, select?: MatSelect) {
    // To be able to call "stepper.next()" without waiting
    // this.consumerFormGroup.get('consumer').updateValueAndValidity({ emitEvent: false });

    const obs = !this.unsavedChanges ? of(true) :
      this.askForConfirm('Changer l\'usager entrainera la perte des modifications de la réservation en cours.');

    obs.subscribe(res => {
      if (res) {
        this.currentReservation.idConsumer = idConsumer;
        this.data.onChangeUsager.next();

        const consumer = this.data.consumers?.find(c => c.id === idConsumer);
        if (consumer?.inscriptions?.length) {
          this.periodeFormGroup.get('inscription').setValue(consumer.inscriptions[0].id);
          this.onChangeInscription(consumer.inscriptions[0].id);
          this.stepper.next();
        }
      } else if (select) {
        // reset to previous value
        select.writeValue(this.currentReservation.idConsumer);
      }
    });
  }

  onChangeInscription(idInscription: number, select?: MatSelect) {
    // Maybe should add a Confirm here too ? (if presences already exist) since it will reset current periode & reservation data too ...

    // skip confirm if no select (should mean this comes from automatic change)
    const obs = !select || !this.unsavedChanges ? of(true) :
      this.askForConfirm('Changer l\'inscription entrainera la perte des modifications de la réservation en cours. Continuer ?');

    obs.subscribe(res => {
      if (res) {
        this.data.currentReservation.idInscription = idInscription;
        // Reset current periode
        this.data.setCurrentPeriode(null);
        this.periodeFormGroup.get('periode').setValue(null);
        this.data.resetPresences();

        // Load periodes for consumer-inscription
        this.loadPeriodesTreeData();
      } else if (select) {
        // reset to previous value
        select.writeValue(this.currentReservation.idInscription);
      }
    });
  }

  onSelectPeriode(periode: PeriodeTreeItem) {
    if (periode && this.data.isCurrentPeriode(periode.id, periode.idPortail)) {
      return;
    }

    const obs = !this.unsavedChanges ? of(true) :
      this.askForConfirm('Le changement de période entrainera la perte des modifications de la réservation en cours. Continuer ?');

    obs.subscribe(res => {
      if (res) {
        this.data.resetPresences();

        const id = this.periodeService.getIdPeriode(periode);

        this.data.setCurrentPeriode(null);
        this.periodeFormGroup.get('periode').setValue(id);

        this.loadInscriptionPeriode(id, this.data.currentReservation.idInscription).subscribe(
          p => this.data.setCurrentPeriode(p),
          err => {
            this.stepper.previous();
            console.error(err);
          }
        );

        this.stepper.next();

      } else {
        // @TODO: reset to previous value (?)
      }
    });
  }

  buildTitle() {
    let title = (this.ids ? '' : 'Nouvelle ') + 'Réservation';

    const usager = this.currentReservation?.idConsumer ? this.data.findConsumer(this.currentReservation.idConsumer) : null;

    if (usager) {
      title += ' : ' + usager.prenom + ' ' + usager.nom;
    }

    if (this.currentPeriode) {
      title += ' - ' + (this.currentPeriode.label || this.currentPeriode.name);
    }

    return title;
  }

  loadPeriodesTreeData() {
    this.loadingTree = true;
    this.periodeTreeSource.data = [];
    this.messagesPersoFromPrograms.filtragePeriode = [];
    this.inscriptionError = null;
    this.refreshSummaryErrors([]); // for globals errors

    this.periodeService.getPeriodesForInscription(this.currentReservation.idInscription).subscribe(result => {
      this.loadingTree = false;
      // Get errors & facture & traces from programs
      this.displayDebugTraces(result.traces);

      if (result.messages && !result.error) {
        this.messagesPersoFromPrograms.filtragePeriode = result.messages;
      }

      if (result.error) { // form error
        this.inscriptionError = result.error;
        this.inscriptionErrorSteps = result.errorSteps;
        // this.inscriptionErrorVaccins = result.errorVaccins;
        this.inscriptionErrorSteps?.forEach(err => err.link = this.generateLinkForErrorStep(result.error, err.stepName));
        this.step = 0;
        // Prevent user to navigate through stepper
        this.consumerFormGroup.setErrors({ consumer: result.error });
      } else if (result.errors) {
        this.refreshSummaryErrors(result.errors);
        this.messagesPersoFromPrograms.filtragePeriode = [];
        this.step = 0;
      } else {
        this.periodeTreeSource.data = result.treeData;
        this.periodeTreeControl.dataNodes = result.treeData;

        this.periodeTreeControl.dataNodes.forEach(node => {
          node.accueils.forEach(accueil => {
            accueil.periodes.forEach(periode => {
              if (this.facturationService.checkPausePaiement(node) && periode.modeValidation === 'payment') {
                periode.pausePaiement = true;
                periode.pausePaiementMessage = node.pausePaiementMessage;
              } else {
                periode.pausePaiement = false;
              }
            })
          })

        });
      }
    });
  }

  togglePeriodeTreeAll() {
    if (this.periodeTreeAllExpanded) {
      this.periodeTreeControl.collapseAll();
    } else {
      this.periodeTreeControl.expandAll();
    }

    this.periodeTreeAllExpanded = !this.periodeTreeAllExpanded;
  }

  toggleMessagePausePaiement(periode) {
    periode.showMessagePausePaiement = !periode.showMessagePausePaiement;
  }

  generateLinkForErrorStep(errorType: string, step: string) {
    const usager = this.data.findConsumer(this.currentReservation.idConsumer);

    switch (errorType) {
      case 'enfant': return `/account/children/edit/${usager.idEnfant}/${step}`;
      case 'family':
      case 'famille': return `/account/foyer/${step}`;
      case 'user': return `/account/user/${step}`;
      case 'conjoint': return `/account/conjoint/${step}`;
      // case 'autreAdulte' : return '';
    }

    return '';
  }

  isPeriode(_, node) {
    return node && node.level === 'periode';
  }

  isNotPeriode(_, node) {
    return !(node && node.level === 'periode');
  }


  get hasPlanningOptions() {
    return this.step === (this.ids ? 0 : 2) && !!this.planningComponent &&
      (!this.currentPeriode || this.currentPeriode.modeSelection !== 'activite');
  }

  togglePlanningOptions() {
    if (!this.lastPanelToggle || (Date.now() - this.lastPanelToggle > 1000)) {
      this.optionsPanelOpened = !this.optionsPanelOpened;
      this.lastPanelToggle = Date.now();
    }
  }

  stepperNext() {
    this.stepper.next();
  }

  stepperPrevious() {
    this.stepper.previous();
  }

  findRubrique(id: number) {
    return this.currentPeriode.rubriques.find(rub => rub.id === id);
  }

  refreshSummaryErrors(errors?: any[]) {
    this.globalsErrors = [];
    this.sumErrorPresences = [];
    this.hasError = false;

    if (errors) {
      this.hasError = errors.length > 0;
      errors.forEach(err => {
        if (err.source && err.source.tmpId) {
          const errorPresence = this.sumReservation.presences.find(pr => pr.tmpId === err.source.tmpId);
          errorPresence.error = err;
        } else {
          this.globalsErrors.push(err.errorMessage);
        }
      });

      if (this.sumReservation) {
        this.sumErrorPresences = this.sumReservation.presences.filter(pr => pr.error);
      }

      if (errors.length) {
        setTimeout(_ => this.scrollToErrorList());
      }
    }
  }

  scrollToErrorList() {
    if (this.errorList.length) {
      const errorListEl = this.errorList.first.nativeElement as HTMLElement;
      const targetPos = errorListEl.offsetTop - 10;

      if (targetPos < window.scrollY) {
        window.scrollTo({ top: targetPos, behavior: 'smooth' });
      }
    }
  }

  displayDebugTraces(traces: string[]) {
    if (traces) {
      traces.forEach(t => console.log(t));
    }
  }

  loadSummary() {
    // Prevent call to the back if there's nothing new
    if (!this.currentReservation?.presences?.length) {
      return;
    }
    this.alertBigReservation = (this.currentReservation?.presences?.length > 100);

    // Display a loader
    // this.refreshSummaryErrors([]); // => aprés une erreur lors de la "pre-validation", l'utilisateur clique sur "retour", rectifie l'erreur
    //                                       ensuite, lorsqu'il "re" clique sur "suivant" pour une nouvelle prévalidation, le bouton "Valider" est actif
    //                                        => En désactivant ceci, c'est OK

    // Send reservation data
    this.reservationService.submitReservation(this.currentReservation, true).subscribe((res: SubmitResult) => {
      this.alertBigReservation = false;

      this.messagesPersoFromPrograms.validationPlanning = [];

      this.hasAttentes = !!res.attentes?.length;

      this.sumReservation = res.reservation;

      // small hack cause I don't want to edit the back to add this ...
      this.sumReservation.presences.forEach(pr => {
        const rubrique = this.data.currentPeriode.rubriques.find(r => r.id === pr.rubrique);
        const activity = pr.activities?.length ? this.data.currentPeriode.activites.find(a => a.id === pr.activities[0]) : null;
        this.planningService.setPresenceAttributes(pr, rubrique, activity);
        pr.status = computePresenceStatus(pr, this.sumReservation);

        // could instead be set on backend directly
        pr.attente = res.attentes?.some(w => w.source?.tmpId === pr.tmpId) ? 1 : null;
      });

      // this.sumReservation.events = this.planningService.buildEventsForPresences(this.sumReservation.presences);

      this.refreshPresencesCount();
      // Get errors & facture & traces from programs
      this.refreshSummaryErrors(res.errors);

      if (res.messages && !res.errors) {
        this.messagesPersoFromPrograms.validationPlanning = res.messages;
      }
      this.displayDebugTraces(res.traces);

      if (this.showFacture) {
        this.facture = res.facture;
      }
    });
  }

  get showFacture(): boolean {
    if (!this.data.currentPeriode) {
      return false;
    }
    return this.data.currentPeriode.factureEstimate || this.data.currentPeriode.modeValidation === 'payment';
  }

  onSummarySelectionChange(event) {
    this.refreshPresencesCount();
  }

  refreshPresencesCount() {
    this.presencesCount = this.sumReservation.presences?.filter(pr => pr.attente !== -1).length;
  }

  submit() {
    this.saving = true;
    this.alertBigReservation = false;
    this.refreshSummaryErrors([]);

    // copy "attente" state from "sum reservation" to "current reservation" (which is sent) ...
    // @TODO: check if we could send sum reservation directly insted ? and actually why we need both .. ?
    this.currentReservation.presences?.forEach(pr => {
      const sumPr = this.sumReservation.presences?.find(spr => spr.tmpId === pr.tmpId);

      if (sumPr) {
        pr.attente = sumPr.attente;
      }
    });

    this.alertBigReservation = (this.currentReservation?.presences?.length > 100);

    this.reservationService.submitReservation(this.currentReservation).subscribe((res: SubmitResult) => {
      this.saving = false;

      this.refreshSummaryErrors(res.errors);
      this.displayDebugTraces(res.traces);

      const redirectUrl = '/account/reservations' + (this.currentPeriode.modeValidation === 'payment' ? '/panier' : '');

      if (res.creation === false) {
        this.snackbar.error('Des erreurs se sont produites à la création de la réservation.');
      } else if (res.validation === false) {
        this.snackbar.open({
          message: 'Enregistrement effectué, mais validation automatique échouée.',
          type: TypeSnackbar.alert,
          textButton: 'OK'
        });
        this.router.navigate([redirectUrl]);
      } else {
        this.snackbar.info('Enregistrement effectué.');
        this.router.navigate([redirectUrl]);
      }
    },
      err => {
        this.snackbar.error(err);
        this.hasError = true;
        this.saving = false;
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
