import { FormlyFieldConfig } from '@ngx-formly/core';

export class LengthValidator {

  static min(err, field: FormlyFieldConfig) {
    return `Minimum ${field.templateOptions.minLength} caractères`;
  }

  static max(err, field: FormlyFieldConfig) {
    return `Maximum ${field.templateOptions.maxLength} caractères`;
  }

}
