<table class="facture-line-table">
  <thead>
    <tr class="primary-darker-bg">
      <th>Désignation</th>
      <th>Quantité</th>
      <th>Tarif</th>
      <th>Montant</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let group of lineGroups">
      <tr class="primary-lighter-bg">
        <td class="line-group-title" colspan="4">
          <b>{{ group.referent }}</b> - {{ group.details }}
        </td>
      </tr>
      <tr class="details-facture" *ngFor="let factureLine of group.lines">
        <td>{{ factureLine.designation }}</td>
        <td class="text-center">{{ factureLine.quantite }}</td>
        <td class="text-right">{{ factureLine.prixUnitaire|currency:'EUR' }}</td>
        <td class="text-right">{{ factureLine.montant|currency:'EUR' }}</td>
      </tr>
    </ng-container>
  </tbody>
  <tfoot>
    <tr class="primary-darker-bg">
      <td colspan="3">Total</td>
      <td>{{ facture.montant|currency:'EUR' }}</td>
    </tr>
  </tfoot>
</table>
