<h3 *ngIf="config && !inscriptionsActive" style="text-align: center;">Les inscriptions ne sont pas disponibles.</h3>

<div class="conteneur" *ngIf="config && inscriptionsActive; else loader">

  <mat-card class="info-card" *ngIf="config.infoMessage" [portailCustomStyle]="config.styleCardInfo">
    <div [innerHtml]="config.infoMessage | safe"></div>
  </mat-card>

  <mat-card *ngIf="isLoaded; else loader"
    [ngClass]="{'form-container': !platformService.isMobile, 'form-container-mobile': platformService.isMobile}">
    <mat-card-header class="card-header" fxLayout="row" fxLayoutAlign="center center">
      <mat-card-title fxLayout="row" fxLayoutAlign="center center">
        <span *ngIf="!isEdit">{{ 'diabolo.new_inscription'|trans }}</span>
        <span *ngIf="isEdit">{{ inscription.nomReferent }}</span>
      </mat-card-title>
      <mat-card-subtitle *ngIf="isEdit">
        <label>{{ 'diabolo.edit.card_subtitle'|trans }}</label>
        <ng-container *ngIf="inscription.dateDebut && inscription.dateDebut != '0000-00-00'">
          <span *ngIf="inscription.dateFin && inscription.dateFin != '0000-00-00'; else fromDate">
            Du {{ inscription.dateDebut|date:'shortDate' }} au {{ inscription.dateFin|date:'shortDate' }}
          </span>
          <ng-template #fromDate>
            <span>À partir du {{ inscription.dateDebut|date:'shortDate' }}</span>
          </ng-template>
        </ng-container>
      </mat-card-subtitle>
    </mat-card-header>

    <ng-container *ngIf="!isEdit">
      <mat-form-field>
        <mat-label>{{ 'entity.usager'|trans }}</mat-label>
        <mat-select [value]="selectedUsager" required panelClass="usager-select"
          (selectionChange)="onChangeUsager($event)">
          <mat-optgroup>
            {{ 'entity.adults'|trans }}
            <ng-container *ngFor="let usager of listUsagers">
              <mat-option *ngIf="usager.type === 'adulte'" [value]="usager">
                {{ usager.prenom }} {{ usager.nom }}
              </mat-option>
            </ng-container>
          </mat-optgroup>

          <mat-optgroup>
            {{ 'entity.children'|trans }}
            <ng-container *ngFor="let usager of listUsagers">
              <mat-option *ngIf="usager.type === 'enfant'" [value]="usager">
                {{ usager.prenom }} {{ usager.nom }}
                <span *ngIf="usager.age">({{ 'enfant.age'|trans:{age: usager.age.years} }})</span>
              </mat-option>
            </ng-container>
          </mat-optgroup>
        </mat-select>
      </mat-form-field>
      <div *ngIf="config.optionDatesInscription === 'enabledDatesInscription'" fxLayout="row wrap"
        fxLayoutAlign="center center" [ngStyle]="{'width': platformService.isMobile ? '100%' : '70%'}"
        style="margin: auto;">
        <mat-form-field fxFlex="50%" fxFlex.xs="100%">
          <mat-label>Du</mat-label>
          <input matInput [matDatepicker]="openFromDatePicker" [min]="dateNow" [max]="inscription.dateFin"
            [(ngModel)]="inscription.dateDebut" placeholder="jj/mm/aaaa" (dateChange)="dateChange($event,'dateDebut')">
          <mat-datepicker-toggle matSuffix [for]="openFromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #openFromDatePicker disabled="false"></mat-datepicker>
        </mat-form-field>

        <mat-form-field fxFlex="50%" fxFlex.xs="100%">
          <mat-label>au</mat-label>
          <input matInput [matDatepicker]="openUntilDatePicker" [min]="inscription.dateDebut"
            [(ngModel)]="inscription.dateFin" placeholder="jj/mm/aaaa" (dateChange)="dateChange($event,'dateFin')">
          <mat-datepicker-toggle matSuffix [for]="openUntilDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #openUntilDatePicker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>

      <div *ngIf="config.optionDatesInscription === 'enabledDateDebutInscription'" fxLayout="row wrap"
        fxLayoutAlign="center center" style="margin: auto;">
        <mat-form-field>
          <mat-label>À partir du</mat-label>
          <input matInput [matDatepicker]="openFromDatePicker" [(ngModel)]="inscription.dateDebut"
            placeholder="jj/mm/aaaa" (dateChange)="dateChange($event,'dateDebut')">
          <mat-datepicker-toggle matSuffix [for]="openFromDatePicker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #openFromDatePicker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>

    </ng-container>
    <div [ngStyle]="{'width': platformService.isMobile ? '100%' : '70%'}" class="container-periode">
      <label style="font-weight: bold;">{{ 'diabolo.edit.selection_periode'|trans }}</label>

      <div *ngIf="!loadingPeriode; else loader" style="margin-top: 20px;" class="treeview-container">

        <!-- <button mat-raised-button *ngIf="inscription.usager && !noAvailablePeriodes" color="accent-lighter" (click)="toggleExpandAll()"
        [matTooltip]="isAllExpanded ? 'Tout refermer' : 'Tout ouvrir'" style="margin-bottom: .5em;">
            <mat-icon>{{ isAllExpanded ? 'chevron_right' : 'expand_more' }}</mat-icon>
            Tous
        </button> -->

        <mat-tree [dataSource]="treeSource" [treeControl]="treeControl">

          <mat-tree-node *matTreeNodeDef="let periode; when: isPeriode" matTreeNodeToggle matTreeNodePadding>
            <span class="node-title">
              <mat-checkbox class="checklist-leaf-node" [checked]="selectionModel.isSelected(periode)"
                (change)="selectionModel.toggle(periode); runProgramsOnChangePeriodes();"
                [disabled]="periode.disabled || !loadedParamsLibres">
                <i class="icon icodomino-periode" style="margin-right: 5px;"></i>
                {{ periode.name }} {{ periode.label ? '(' + periode.label + ')' : '' }}
              </mat-checkbox>
            </span>
          </mat-tree-node>

          <mat-nested-tree-node *matTreeNodeDef="let node; when: !isPeriode" matTreeNodePadding>
            <span class="node-title">
              <button type="button" mat-icon-button matTreeNodeToggle color="primary"
                [attr.aria-label]="'toggle ' + node.name "
                [disabled]="node.level == 'etablissement' && node.accueils?.length == 0 || (node.level == 'accueil' || node.level === 'etablissement') && node.periodes?.length == 0">
                <mat-icon>
                  {{ treeControl.isExpanded(node) ? "keyboard_arrow_down" : "keyboard_arrow_right" }}</mat-icon>
              </button>

              <mat-checkbox [checked]="descendantsAllSelected(node)"
                [indeterminate]="descendantsPartiallySelected(node)"
                (change)="itemSelectionToggle(node); runProgramsOnChangePeriodes();" style="margin-left: 10px;"
                [disabled]="node.disabled || !loadedParamsLibres">
                <i class="icon icodomino-etablissement" fxFlexAlign="center" style="margin-right: 5px;"
                  *ngIf="node.level == 'etablissement'"></i>
                <i class="icon icodomino-accueil" fxFlexAlign="center" style="margin-right: 5px;"
                  *ngIf="node.level == 'accueil'"></i> {{ node.name }}
              </mat-checkbox>

            </span>
            <ul [class.tree-node-invisible]="!treeControl.isExpanded(node)">
              <ng-container matTreeNodeOutlet></ng-container>
            </ul>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
      <div *ngIf="!inscription.usager" style="margin-top: 20px;">{{ 'diabolo.edit.no_user_selected'|trans }}</div>
      <div *ngIf="noAvailablePeriodes">{{ 'diabolo.edit.no_periods_available'|trans }}</div>
    </div>

    <ng-template #loader>
      <app-loader style="margin-top: 20px;"> {{ 'diabolo.edit.load_periodes'|trans }}</app-loader>
    </ng-template>

    <ng-container *ngIf="!loadingPeriode || !config?.programsOnSelectPeriode.length">
      <ng-container
        *ngIf="(selectionModel?.selected.length && config?.programsOnSelectPeriode.length) || !config?.programsOnSelectPeriode.length">
        <div style="width: 70%; margin: auto;" *ngIf="loadedParamsLibres; else loaderParamsLibres">
          <app-params-libre *ngFor="let paramLibre of paramsLibres" class="params-libres-component"
            [paramLibre]="paramLibre" [ngStyle]="{'padding': paramLibre.nature ==='Booléen' ? '0 0 20px 0' : '0'}">
          </app-params-libre>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loaderParamsLibres>
      <app-loader message="{{ 'diabolo.edit.load_params_libres'|trans }}" style="margin-top: 20px;">
      </app-loader>
    </ng-template>

    <mat-form-field *ngIf="config.enabledComment">
      <mat-label>Commentaire</mat-label>
      <textarea matInput [(ngModel)]="inscription.commentaire" cdkTextareaAutosize cdkAutosizeMinRows="3"></textarea>
    </mat-form-field>

    <mat-card-actions align="center">
      <button mat-raised-button color="primary" (click)="onClickValidateInscription()" [disabled]="saving ||
                  !loadedParamsLibres ||
                  !checkParamsLibres() ||
                  !inscription.usager ||
                  !selectionModel.selected.length ||
                  !checkValidityDates(inscription.dateDebut, inscription.dateFin) ||
                  loadingPeriode">
        Valider
        <mat-progress-spinner *ngIf="saving" mode="indeterminate" strokeWidth="2" diameter="24" color="accent-lighter"
          class="icon-loader"></mat-progress-spinner>
      </button>
    </mat-card-actions>

  </mat-card>
</div>

<ng-template #loader>
  <app-loader class="margin"></app-loader>
</ng-template>
